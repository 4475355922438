@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../../node_modules/rc-slider/assets/index.css";

html, body, #__next {
  height: 100%;
  overscroll-behavior: none;

  @apply bg-gray-100;
}

input[type=number].appearance-none::-webkit-inner-spin-button,
input[type=number].appearance-none::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@import "./cursor.css";
@import "./geolocation.css";
@import "./nprogress.css";
